<template>
  <action-dispatcher
    ref="dispatcher"
    action="questions/get"
    :parameters="$route.params.id"
    @success="success"
  >
    <div class="view-questions">
      <div class="d-flex justify-content-between">
        <button
          class="btn btn-back mb-4"
          @click="$router.push({ name: 'admin-exams' })"
        >
          <i class="icon-angle-left mr-2"></i> Voltar
        </button>
        <router-link class="btn py-1" :to="{ name: 'admin-answers' }">
          Ver Respostas
        </router-link>
      </div>
      <div class="d-flex aling-items-center justify-content-end mb-5">
        <h5 class="font-weight-normal flex-fill">
          Prova: <span class="font-weight-bold">{{ moduleName }}</span>
        </h5>
        <h5 class="font-weight-normal flex-fill">
          Status da prova:
          <span class="font-weight-bold">{{ examStatus }}</span>
        </h5>
        <router-link
          class="btn btn-primary"
          :to="{ name: 'admin-new-exam', query: { module: $route.params.id } }"
        >
          Nova Pergunta
        </router-link>
      </div>

      <div v-if="sortedQuestions.length == 0" class="text-center my-5">
        <p class="text-secondary">
          Ainda não temos questões cadastradas nesta prova
        </p>
      </div>

      <draggable
        v-else
        v-model="sortedQuestions"
        handle=".handle"
        group="questions"
      >
        <div
          class="card d-flex flex-row align-items-center p-2 mb-4"
          v-for="(question, index) in sortedQuestions"
          :key="index"
        >
          <i class="icon-drag-indicator handle lead mr-3"></i>
          <h6 class="font-weight-normal m-0 p-0 flex-fill">
            {{ `${index + 1} - ${question.title}` }}
          </h6>
          <router-link
            :to="{ name: 'admin-edit-question', params: { id: question.id } }"
            class="btn m-0 p-0 mr-2"
          >
            <i class="icon-edit"></i>
          </router-link>
          <button
            class="btn text-dangers m-0 p-0"
            @click="openDeleteDialog(question)"
          >
            <i class="icon-trash text-danger"></i>
          </button>
        </div>
      </draggable>

      <confirmation
        v-model="deleteDialog"
        @confirm="submitDeleteQuestion"
        :text="deleteConfirmationText"
        confirm-button-text="Sim, excluir!"
        confirm-button-class="btn-danger"
      >
        <template #img>
          <h1><i class="icon-construction"></i></h1>
        </template>
      </confirmation>
    </div>
  </action-dispatcher>
</template>
<script>
import draggable from "vuedraggable";
import get from "lodash/get";
export default {
  components: { draggable },
  mounted() {
    this.$store
      .dispatch("content/getModule", this.$route.params.id)
      .then(data => {
        this.currentModule = data;
      });
  },
  computed: {
    moduleName() {
      return get(this.currentModule, "name", "");
    },
    examStatus() {
      const expire_at = get(this.currentModule, "exam_expire_at", false);

      if (expire_at) {
        const formatted_date = new Date(
          expire_at.seconds * 1000
        ).toLocaleDateString();
        return `Prova liberada até: ${formatted_date}`;
      }

      return "Indisponível para resposta";
    },
    sortedQuestions: {
      get() {
        return [...this.questions].sort((a, b) => a.sort - b.sort);
      },
      set(value) {
        const questions = [...this.questions];
        for (let i in questions) {
          for (let j in value) {
            if (questions[i].id === value[j].id) {
              questions[i].sort = j;
            }
          }
        }
        this.questions = questions;
        this.saveOrder();
      }
    },
    deleteConfirmationText() {
      if (this.questionToDelete) {
        const limit = 30;
        let title = this.questionToDelete.question;
        let ellipsis = "";
        if (title.length > limit) {
          title = this.questionToDelete.question.slice(0, limit);
          ellipsis = "...";
        }

        return `Tem certeza que deseja excluir a pergunta: ${title}${ellipsis}?`;
      }

      return "";
    }
  },
  data() {
    return {
      questionToDelete: null,
      reorderLoading: false,
      deleteLoading: false,
      deleteDialog: false,
      currentModule: null,
      questions: []
    };
  },
  methods: {
    success(data) {
      this.questions = data;
    },
    openDeleteDialog(question) {
      this.questionToDelete = question;
      this.deleteDialog = true;
    },
    submitDeleteQuestion() {
      this.deleteLoading = true;
      this.$store
        .dispatch("questions/delete", this.questionToDelete.id)
        .then(() => {
          this.$message.success("A questão foi deletada");
          this.questionToDelete = null;
          this.deleteDialog = false;
          this.$refs.dispatcher.dispatch();
        })
        .catch(() => {
          this.$message.error("Não foi possível excluir a questão");
        })
        .finally(() => {
          this.deleteLoading = false;
        });
    },
    saveOrder() {
      this.reorderLoading = true;
      this.$store.dispatch("questions/reorder", this.questions).finally(() => {
        this.reorderLoading = false;
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.view-questions {
  .handle {
    cursor: pointer;
  }
}
</style>
